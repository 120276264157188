export const getCourseFullDetails =
  `query getCourse($id: ID!) {
      getCourse(id: $id) {
        id
        slug
        title
        module{
          id
          slug
          title
          sequence
        }
        courseSection {
          items {
            id
            title
            text
            sequence
            type
            backgroundImg
            courseContent {
              items {
                id
                title
                text
                sequence
                mediaURL
              }
            }
            quiz {
              items {
                question {
                  items {
                    id
                    title
                    explanation
                    sequence
                    answerOptions {
                      items {
                        id
                        isCorrect
                        explanation
                        sequence
                        text
                        subQuestionIndex
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

export const getCourseWithQuizDetails =
  `query getCourseWithQuizDetails($id: ID!) {
                    getCourse(id: $id) {
                        quiz {
                            items {
                                question {
                                    items {
                                        id
                                        answer {
                                            id
                                            option
                                        }
                                        answerOptions {
                                            items {
                                                id
                                                option
                                                sequence
                                            }
                                        }
                                        question
                                        explanation
                                    }
                                }
                            }
                        }
                        title
                        type
                    }
                }`;

export const getUserProgressWithUserID = `query test($filter: ModelUserFilterInput){
                    listUsers(filter:$filter, first:1, skip: 5) {
                        items {
                            id
                            userQuizAnswers {
                              nextToken
                            }
                            cognitoUserID
                            identificationNumber
                            identificationType
                            fullname
                            nationality
                            mobilePhoneNumber
                            email
                            username
                            password
                            dob
                            dateOfAttendance
                            language
                            createdAt
                            modifiedAt
                          }
                          nextToken
                    }
                }`

export const getEvaluationQuestion = `query test{
  listEvaluationQuestionSections{
    items{
      id
      sequence
      title
      type
      question{
        items{
          id
          sequence
          title
          createdAt
          modifiedAt
        }
      }
      ratingScale{
        items{
          id
          sequence
          text
          value
          createdAt
          modifiedAt
        }
      }
      createdAt
      modifiedAt
    }
  }
}`

export const getUserAuthRecord = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    fullname
    dateOfAttendance
    skipSingPass
    firstTimer
    paymentStatus
    validityDate
    emailMarketingOption
    smsMarketingOption
    createdAt
    modifiedAt
    language
    userQuizAnswers {
      items {
        id
      }
      nextToken
    }
    userEvaluationAnswers {
      items {
        id
      }
      nextToken
    }
  }
}
`;

export const getPaymentTransactionCallback = `query GetPaymentTransaction($id: ID!) {
  getPaymentTransaction(id: $id) {
    id
    transactionID
    referenceNo
    status
    chequeNumber
    bankName
    eNetsTransactionID
    creditCardNumber
    paymentMethod
    modeOfDelivery
    language
    amount
    userID
    fullname
    identificationNumber
    identificationType
    createdAt
    modifiedAt
  }
}
`;


export const updatePaymentTransactionRemarks = `mutation UpdatePaymentTransaction($input: UpdatePaymentTransactionInput!) {
  updatePaymentTransaction(input: $input) {
    id
    remarks
  }
}
`;


export const listCourseModuleResources = `query ListCourseModules(
  $filter: ModelCourseModuleFilterInput
  $limit: Int
  $nextToken: String
) {
  listCourseModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sequence
      title
      slug
      img
      createdAt
      modifiedAt
      course {
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
        }
        nextToken
      }
    }
    nextToken
  }
}
`;
// export const listCourseResources= `query ListCourses(
//   $filter: ModelCourseFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       sequence
//       title
//       slug
//       name
//       type
//       backgroundImg
//       createdAt
//       modifiedAt
//       module {
//         id
//         sequence
//         title
//         slug
//         img
//         createdAt
//         modifiedAt
//       }
//       courseSection {
//         nextToken
//       }
//       courseResource {
//         items {
//           id
//           language
//           title
//           text
//           media
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;

// export const listCourseSectionResources = `query ListCourseSections(
//   $filter: ModelCourseSectionFilterInput
//   $limit: Int
//   $nextToken: String
// ) {
//   listCourseSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
//     items {
//       id
//       sequence
//       title
//       text
//       type
//       backgroundImg
//       createdAt
//       modifiedAt
//       course {
//         id
//         sequence
//         title
//         slug
//         name
//         type
//         backgroundImg
//         createdAt
//         modifiedAt
//       }
//       courseContent {
//         nextToken
//       }
//       quiz {
//         nextToken
//       }
//       courseResource {
//         items {
//           id
//           language
//           title
//           text
//           media
//         }
//         nextToken
//       }
//     }
//     nextToken
//   }
// }
// `;

export const getCourseFullDetailsResources =
  `query getCourse($id: ID!) {
      getCourse(id: $id) {
        id
        slug
        title
        module{
          id
          slug
          title
          sequence
          courseResource {
            items {
              id
              language
              title
            }
            nextToken
          }
        }
        courseResource {
          items {
            id
            language
            title
            text
            media
          }
          nextToken
        }
        courseSection {
          items {
            id
            title
            text
            sequence
            type
            backgroundImg
            courseResource {
              items {
                id
                language
                title
                text
                media
              }
              nextToken
            }
            courseContent {
              items {
                id
                title
                text
                sequence
                mediaURL
                courseResource {
                  items {
                    id
                    language
                    title
                    text
                    media
                  }
                  nextToken
                }
              }
            }
            quiz {
              items {
                question {
                  items {
                    id
                    title
                    explanation
                    sequence
                    quizResource {
                      items {
                        id
                        language
                        title
                        text
                        media
                      }
                      nextToken
                    }
                    answerOptions {
                      items {
                        id
                        isCorrect
                        explanation
                        sequence
                        text
                        subQuestionIndex
                        quizResource {
                          items {
                            id
                            language
                            title
                            text
                            media
                            explanation
                          }
                          nextToken
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `;
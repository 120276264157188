import React, { useEffect, useState } from 'react'
import { Container } from '@material-ui/core';
import { Link } from "react-router-dom";
import './Footer.scss';

export default function Footer(props) {
    return (
        <footer>
            <Container maxWidth="lg">
                <div className="footer-content">
                    <div className="footer-section">
                        <div className="footer-logo-wrapper">
                            <img src="/assets/img/eop-logo.png" alt="" />
                        </div>
                        <p><a className="fb-link" href="https://www.facebook.com/gracemanagementandconsultancyservices/" target="_blank"></a></p>
                    </div>
                    <div className="footer-section">
                        <h4>Contact Us</h4>
                        <p>198 Geylang Road #03-01</p>
                        <p>Singapore 389263</p>
                        <p>Tel: 6299 4333</p>
                        <p>eop@gmcs.com.sg</p>
                    </div>
                </div>
                <div className="footer-content-bottom">
                    <div className="footer-section">
                        Copyright © 2014–2020 Grace Management & Consultancy Services Pte Ltd.
                    </div>
                    <div className="footer-section footer-links">
                        <a href="http://www.gmcs.com.sg/contact/">Contact</a>
                        <a href="http://www.gmcs.com.sg/privacy-policy/">Privacy Notice</a>
                        <a href="http://www.gmcs.com.sg/terms-of-use/">Terms of Use</a>
                    </div>
                </div>
            </Container>
        </footer >
    )
}

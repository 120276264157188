import React, { Component } from 'react'
import { Container, Typography, Button } from '@material-ui/core';
import './CourseCompletionPage.scss'

import { API, graphqlOperation } from 'aws-amplify';
import { getUser, getUserRecord } from '../../../graphql/queries';
import { checkCourseCompletion } from '../../../graphql/mutations';
import { instanceOf } from 'prop-types';
import {withCookies, Cookies} from 'react-cookie'
import * as moment from 'moment'

class CourseCompletionPage extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    state = {
        isPrintReady: false,
        name: '',
        id: '',
        attendance_date: '',
        activityName: 'EOP training completion',
        signPosition: 'Director',
        signCompany: 'Grace Management & Consultancy Services Pte Ltd'
    }

    initPrint(){
        setTimeout(
            function() {
                window.print();
            }
            .bind(this),
            2000
        );  
    }

    componentDidMount() {
        this.forceUpdate()   
        const { cookies } = this.props; 
        let userid = cookies.get('user_token');
        API.graphql(graphqlOperation(checkCourseCompletion, { "userid": userid }))
        .then(response => {
            if(response.data.checkCourseCompletion){
                API.graphql(graphqlOperation(getUserRecord, { "id": userid }))
                .then(response => {
                    let userDetails = response.data.getUserRecord
                    if(userDetails){
                        this.setState({
                            isPrintReady: true,
                            name: userDetails.fullname,
                            id: userDetails.identificationNumber,
                            attendance_date: moment(userDetails.dateOfAttendance).isValid() ? moment(userDetails.dateOfAttendance).format("DD/MM/YYYY") : userDetails.dateOfAttendance
                        });
                        this.initPrint()
                    }
                    else{
                        this.props.history.push('/course/summary')
                    }
                }, error => {
                    this.props.history.push('/course/summary')
                })
            }
            else{
                this.props.history.push('/course/summary')
            }
        }, error => {
            this.props.history.push('/course/summary')
        })
        

        
        //let response = await API.graphql(graphqlOperation(checkCourseCompletion, { "userid": params }));

    }
    certificateDownloadLink() {
        window.print()
    }

    render() {
        let output = <Container maxWidth="md">
                    <React.Fragment>
                        <div className='certificate'>
                            <br />
                            <div className="logo">
                                <img src="/assets/img/gmc-logo.png" alt="" />
                                <img src="/assets/img/mom-logo.png" alt="" />
                            </div>
                            <br />
                            <br />
                            <Typography variant="h4" className="title">Certificate of Attendance</Typography>
                            <br />

                            <Typography>This certificate is awarded to</Typography>
                            <br />
                            <Typography>{this.state.name}</Typography>
                            <br />
                            
                            
                            <Typography>NRIC/FIN Number:</Typography>
                            <br />
                            <Typography>{this.state.id}</Typography>
                            <br/>
                            <Typography>For attending</Typography>
                            
                            <br />                      
                            <Typography>{this.state.activityName}</Typography>
                            <br />
                            <Typography>on:</Typography>
                            <br />
                            <Typography>{this.state.attendance_date}</Typography>
                            <br />
                            <img src="/assets/sign.png" alt="" />

                            <br/>
                            <Typography>{this.state.signPosition}</Typography>
                            <Typography>{this.state.signCompany}</Typography>
                                                                

                            <br />
                            <div className="print-button">
                                <Button variant="contained" color="primary" onClick={this.certificateDownloadLink} disabled={!this.state.isPrintReady}>
                                    Print
                                </Button>
                            </div>
                        </div>
                    </React.Fragment>
                </Container>
        return (
            <React.Fragment>
                {this.state.isPrintReady ? output : null}
            </React.Fragment> 
        )
    }
}

export default withCookies(CourseCompletionPage)
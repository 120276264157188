import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

export default function NavBarMobileMenu() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            className="navbar-mobile-list"
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <List>
                <ListItem button key="AboutGMC">
                    <ListItemText><a href="http://www.gmcs.com.sg/" rel="noopener noreferrer">About GMC</a></ListItemText>
                </ListItem>
                <ListItem button key="GMSCourses">
                    <ListItemText>
                        GMC Courses
                    </ListItemText>
                </ListItem>
                <ListItem button key="GMSCourse1" className="sub-menu-list-item">
                    <ListItemText>
                        <a target="_blank" rel="noopener noreferrer" href="http://www.gmcs.com.sg/courses/ministry-of-manpower-approved-courses/certificate-of-employment-intermediaries-cei/">Ministry of Manpower Approved Courses</a>
                    </ListItemText>
                </ListItem>
                <ListItem button key="GMSCourse2" className="sub-menu-list-item">
                    <ListItemText>
                        <a target="_blank" rel="noopener noreferrer" href="http://www.gmcs.com.sg/courses/caregiver-training-grant-ctg-courses/elderly-daily-care/">Caregiver Training Grant (CTG) courses</a>
                    </ListItemText>
                </ListItem>
                <ListItem button key="GMSCourse3" className="sub-menu-list-item">
                    <ListItemText>
                        <a target="_blank" rel="noopener noreferrer" href="http://www.gmcs.com.sg/courses/in-house-programs/basic-conversational-english/">In-House Courses</a>
                    </ListItemText>
                </ListItem>
            </List>
        </div>
    );

    return (
        <div className="mobile-sidebar-btn">
            <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={toggleDrawer('right', true)}>
                <MenuIcon />
            </IconButton>
            <SwipeableDrawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer('right', false)}
                onOpen={toggleDrawer('right', true)}
                className="mobile-sidebar-menu"
            >
                {sideList('right')}
            </SwipeableDrawer>
        </div>
    );
}
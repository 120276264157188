import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from '../components/navbar/NavBar';
import Footer from '../components/footer/Footer';
import AppRouting from '../infrastructure/AppRouting';
import CourseCompletionPage from '../pages/course/course-completion/CourseCompletionPage';
import {useCookies} from 'react-cookie'

function AuthenticatedRoute(myProps){
    return <React.Fragment>
        <Switch>
            <Route path={'/course-completion'} render={(props) => <CourseCompletionPage {...props}/>} {...myProps}/>
        </Switch>
    </React.Fragment>
}
function AuthRoute(props){
    const [cookies, setCookie, removeCookie] = useCookies(['eop']);
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    let userid = cookies.user_token;
    useEffect(() => {
        if(userid){
            if(userid === ''){
                props.history.push('/login')
            }
            else{
                // let params = {
                //     id: userid
                // }
                // API.graphql(graphqlOperation(getUser, params))
                // .then(response => {
                //     if(response.data.getUser.skipSingPass){
                //         setIsAuthenticated(true)
                //     }
                //     else{
                //         props.history.push('/singpass')
                //     }
                // }, error => {
                //     setIsAuthenticated(true)
                // });

                //temp solution for no singpass logged in record
                setIsAuthenticated(true)
            }
        }
        else{
            // props.history.push('/otplogin')
            props.history.push('/login')
        }
    })
    return <React.Fragment>
        {isAuthenticated ? <AuthenticatedRoute {...props} userid={userid}/> : null}
    </React.Fragment>
}

export function getCurrentProtectToken() {
    return true;
}

export function ProtectRoute(){
    const [refresh, setRefresh] = useState(false)
    const [isLogoutTriggered, setIsLogoutTriggered] = useState(false)

    function isRefreshed(cookies){
        setRefresh(!refresh);
    }

    function triggerLogout(props){
        setIsLogoutTriggered(true)
        isRefreshed();
        props.history.push('/')
    }

    return <Router>
        <Switch>
            <Route exact path="/refresh" render={(props) => {
                setTimeout(() => {
                    props.history.push(process.env.REACT_APP_HOMEPAGE_URL);
                }, 1000)
                return null
            }}/>
            <Route exact path="/" render={ (props) => <BasePage isLogoutTriggered={isLogoutTriggered} setIsLogoutTriggered={setIsLogoutTriggered} refresh={refresh} isRefreshed={isRefreshed} triggerLogout={triggerLogout}/>} />
            <Route exact path="/registration/agreement" render={(props) => 
                <BasePage isLogoutTriggered={isLogoutTriggered} setIsLogoutTriggered={setIsLogoutTriggered} refresh={refresh} isRefreshed={isRefreshed} triggerLogout={triggerLogout} {...props}/>}/>
            <Route exact path="/registration/form" render={(props) => 
                <BasePage isLogoutTriggered={isLogoutTriggered} setIsLogoutTriggered={setIsLogoutTriggered} refresh={refresh} isRefreshed={isRefreshed} triggerLogout={triggerLogout} {...props}/>}/>
            <Route render={(props) => {
                return <BasePage isLogoutTriggered={isLogoutTriggered} setIsLogoutTriggered={setIsLogoutTriggered} refresh={refresh} isRefreshed={isRefreshed} triggerLogout={triggerLogout} {...props}></BasePage>
            }}/>

        </Switch>
    </Router>
}

function BasePage(props){
    const {isLogoutTriggered, setIsLogoutTriggered, refresh, isRefreshed, triggerLogout} = props

    return (
        <React.Fragment>
            {/* // this is where you can add header */}
            <NavBar isLogoutTriggered={isLogoutTriggered} setIsLogoutTriggered={setIsLogoutTriggered} refresh={refresh} {...props}/>
            <AppRouting isRefreshed={isRefreshed} triggerLogout={triggerLogout} {...props}/>
            <Footer></Footer>
        </React.Fragment>
    )

}

export default BasePage
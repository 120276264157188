// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://2q6e4fxkrvcfrprdmjf2jpbare.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bbtaxcofcbhrhfq4xwszemytoy",
    "aws_content_delivery_bucket": "gmceopweb-20190806171938-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d122lbzo0vhncn.cloudfront.net",
    "aws_cognito_identity_pool_id": "ap-southeast-1:c2a8bee1-ffa7-40a7-8a6e-64ceb492c88d",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_39PVy89z6",
    "aws_user_pools_web_client_id": "3in1a0tfmd1mtnfllb42vplq2d",
    "oauth": {}
};


export default awsmobile;

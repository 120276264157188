import { API, graphqlOperation } from 'aws-amplify';
import { getUserRecord } from '../graphql/queries';
import Cookies from 'universal-cookie';
import { getUserAuthRecord } from './CustomGraphQL';

export async function getUserAuth(id = '', returnData = false) {
    const cookies = new Cookies();
    let getUserResp = null;

    let userCookie = cookies.get('user_token');

    if (id) {
        userCookie = id;
    }

    let singPassCookie = cookies.get('singpass_token');
    let userLanguageCookie = cookies.get('user-language');

    let response = {
        id: userCookie,
        paymentStatus: false,
        skipSingPass: false,
        userIsAuthorized: false,
        userHasLogin: false,
        userIsInvalid: false,
        data: null,
        userLanguage: userLanguageCookie
    };

    if (!userCookie) {
        return response;
    }
    else {
        response.userHasLogin = true;
    }

    try {
        // if(returnData) {
        //     getUserResp = await API.graphql(graphqlOperation(getUser, { id: userCookie }));
        // }
        // else {
        //     getUserResp = await API.graphql(graphqlOperation(getUserAuthRecord, { id: userCookie }));
        // }
        getUserResp = await API.graphql(graphqlOperation(getUserRecord, { id: userCookie }));

        if (!getUserResp.data.getUserRecord) {
            return null;
        }
        
        if (getUserResp.data.getUserRecord.validityDate && new Date(getUserResp.data.getUserRecord.validityDate) < new Date()) {
            response.userIsInvalid = true;
        }

        if (getUserResp.data.getUserRecord.paymentStatus == true) {
            response.paymentStatus = true;
        }

        if (getUserResp.data.getUserRecord.skipSingPass == true) {
            response.skipSingPass = true;
        }

        if (response.paymentStatus == true && ((response.skipSingPass == false && singPassCookie) || (response.skipSingPass == true && !singPassCookie))) {
            response.userIsAuthorized = true;
        }

        if (!response.userLanguage) {
            response.userLanguage = getUserResp.data.getUserRecord.language;
            setUserLanguageCookie(response.userLanguage);
        }

        response.data = getUserResp.data.getUserRecord;
    }
    catch {
        return null;
    }

    return response;
}

export function setUserCookie(id) {
    const cookies = new Cookies();
    let TWO_DAYS = 60 * 60 * 24 * 2;
    cookies.set('user_token', id, { maxAge: TWO_DAYS, path: '/' });
}

export function setUserSingPassCookie(id) {
    const cookies = new Cookies();
    let TWO_DAYS = 60 * 60 * 24 * 2;
    cookies.set('singpass_token', id, { maxAge: TWO_DAYS, path: '/' });
}
export function setUserLanguageCookie(id) {
    const cookies = new Cookies();
    let TWO_DAYS = 60 * 60 * 24 * 2;
    cookies.set('user-language', id, { maxAge: TWO_DAYS, path: '/' });
}

export function removeUserCookies(id) {
    const cookies = new Cookies();
    cookies.remove('user_token', { path: '/' });
    cookies.remove('singpass_token', { path: '/' });
    cookies.remove('announcementOpened', { path: '/' });
    cookies.remove('user-language', { path: '/' });
}

export function getCurrentUserID() {
    const cookies = new Cookies();
    return cookies.get('user_token');
}

export function getCurrentUserLanguage() {
    const cookies = new Cookies();
    return cookies.get('user-language');
}

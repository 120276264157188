import React, { useState } from 'react';

const LoginContext = React.createContext([{}, () => {}]);

const LoginProvider = (props) => {
  const [state, setState] = useState({
    'skipSingPass': false,
    'paymentStatus': false
  });

  return (
    <LoginContext.Provider value={[state, setState]}>
      {props.children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
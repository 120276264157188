/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGeneralConfig = /* GraphQL */ `
  query GetGeneralConfig {
    getGeneralConfig {
      settingsId
      disableEmailOtp
      disableMobileOtp
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;
export const listAllCourses = /* GraphQL */ `
  query ListAllCourses($userID: ID) {
    listAllCourses(userID: $userID) {
      type
      sequence
      title
      slug
      subitems {
        type
        sequence
        title
        slug
        userProgress
      }
    }
  }
`;
export const getCourseDetailsBySlug = /* GraphQL */ `
  query GetCourseDetailsBySlug($slug: String, $userID: ID) {
    getCourseDetailsBySlug(slug: $slug, userID: $userID) {
      id
      courseParentCourseId
      courseQuizId
      type
      sequence
      title
      slug
      videoUrl
      createdAt
      modifiedAt
      prevCourse {
        id
        courseParentCourseId
        courseQuizId
        type
        sequence
        title
        slug
        videoUrl
        createdAt
        modifiedAt
      }
      nextCourse {
        id
        courseParentCourseId
        courseQuizId
        type
        sequence
        title
        slug
        videoUrl
        createdAt
        modifiedAt
      }
    }
  }
`;
export const getCourseMeta = /* GraphQL */ `
  query GetCourseMeta($moduleID: ID, $courseID: ID, $userID: ID) {
    getCourseMeta(moduleID: $moduleID, courseID: $courseID, userID: $userID) {
      nextCourseSlug
      prevCourseSlug
      lastCourseSlug
    }
  }
`;
export const retrieveUserProgress = /* GraphQL */ `
  query RetrieveUserProgress($userID: String) {
    retrieveUserProgress(userID: $userID) {
      id
      userID
      courseID
      moduleID
      progressPercentage
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const checkModuleAvailability = /* GraphQL */ `
  query CheckModuleAvailability($userid: String) {
    checkModuleAvailability(userid: $userid)
  }
`;
export const getUserQuizAnswerLambda = /* GraphQL */ `
  query GetUserQuizAnswerLambda(
    $userID: String
    $courseSectionID: String
    $questionID: String
  ) {
    getUserQuizAnswerLambda(
      userID: $userID
      courseSectionID: $courseSectionID
      questionID: $questionID
    ) {
      userID
      courseSectionID
      items {
        id
        questionID
        subQuestionIndex
        answerID
        createdAt
        modifiedAt
      }
    }
  }
`;
export const getCurrentAnnouncement = /* GraphQL */ `
  query GetCurrentAnnouncement($input: String) {
    getCurrentAnnouncement(input: $input) {
      id
      title
      text
      startDate
      endDate
      createdAt
      modifiedAt
    }
  }
`;
export const getCompletionPdf = /* GraphQL */ `
  query GetCompletionPdf($userid: String) {
    getCompletionPdf(userid: $userid) {
      certificate
      gmcDocument
    }
  }
`;
export const getReceipt = /* GraphQL */ `
  query GetReceipt($paymentid: String) {
    getReceipt(paymentid: $paymentid)
  }
`;
export const getAnnouncementByDate = /* GraphQL */ `
  query GetAnnouncementByDate(
    $startDate: String
    $endDate: String
    $announcementID: String
  ) {
    getAnnouncementByDate(
      startDate: $startDate
      endDate: $endDate
      announcementID: $announcementID
    )
  }
`;
export const getUserProgressLambda = /* GraphQL */ `
  query GetUserProgressLambda($userID: String) {
    getUserProgressLambda(userID: $userID) {
      id
      sequence
      title
      slug
      name
      type
      backgroundImg
      createdAt
      modifiedAt
      moduleName
      userProgress
      userID
    }
  }
`;
export const isSkipCourse = /* GraphQL */ `
  query IsSkipCourse($userID: String, $slug: String) {
    isSkipCourse(userID: $userID, slug: $slug)
  }
`;
export const getUserEvaluationAnswerLambda = /* GraphQL */ `
  query GetUserEvaluationAnswerLambda($userid: String) {
    getUserEvaluationAnswerLambda(userid: $userid) {
      id
      questionSectionID
      questionID
      answerID
      answerValue
      createdAt
      modifiedAt
    }
  }
`;
export const customSearchUsers = /* GraphQL */ `
  query CustomSearchUsers(
    $filter: CustomSearchUseFilter
    $limit: Int
    $nextToken: String
    $sort: CustomSearchUserSortDirection
  ) {
    customSearchUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        createdAt
        modifiedAt
      }
      total
      nextToken
    }
  }
`;
export const customSearchPayment = /* GraphQL */ `
  query CustomSearchPayment(
    $filter: CustomSearchPaymentFilter
    $limit: Int
    $nextToken: String
    $sort: CustomSearchPaymentSortDirection
  ) {
    customSearchPayment(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        id
        transactionID
        referenceNo
        status
        chequeNumber
        bankName
        eNetsTransactionID
        creditCardNumber
        paymentMethod
        modeOfDelivery
        language
        amount
        userID
        fullname
        identificationNumber
        identificationType
        remarks
        createdAt
        modifiedAt
      }
      total
      nextToken
    }
  }
`;
export const customSearchCourseContentExport = /* GraphQL */ `
  query CustomSearchCourseContentExport(
    $filter: CustomSearchCourseContentExportFilter
    $limit: Int
    $nextToken: String
    $sort: CustomSearchCourseContentExportSortDirection
  ) {
    customSearchCourseContentExport(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        id
        fileName
        expiryDate
        email
        status
        createdAt
        modifiedAt
      }
      total
      nextToken
    }
  }
`;
export const customSearchAnnouncement = /* GraphQL */ `
  query CustomSearchAnnouncement(
    $filter: CustomSearchAnnouncementFilter
    $limit: Int
    $nextToken: String
    $sort: CustomSearchAnnouncementSortDirection
  ) {
    customSearchAnnouncement(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        id
        title
        text
        startDate
        endDate
        createdAt
        modifiedAt
      }
      total
      nextToken
    }
  }
`;
export const listUserEvaluationAnswerLambda = /* GraphQL */ `
  query ListUserEvaluationAnswerLambda(
    $evaluationQuestionFilter: String
    $startDate: String
    $endDate: String
  ) {
    listUserEvaluationAnswerLambda(
      evaluationQuestionFilter: $evaluationQuestionFilter
      startDate: $startDate
      endDate: $endDate
    ) {
      questionID
      answers {
        answerID
        answerValue
        count
      }
    }
  }
`;
export const getUserRecord = /* GraphQL */ `
  query GetUserRecord($id: ID) {
    getUserRecord(id: $id) {
      id
      identificationNumber
      identificationType
      passportNumber
      fullname
      nationality
      mobilePhoneNumber
      email
      dob
      dateOfAttendance
      language
      mode
      skipSingPass
      firstTimer
      paymentStatus
      disallowUserSkippingCourse
      validityDate
      emailMarketingOption
      smsMarketingOption
      hasCompletedAllCourse
      hasRefunded
      createdAt
      modifiedAt
      userQuizAnswers {
        items {
          id
          questionID
          subQuestionIndex
          answerID
          createdAt
          modifiedAt
        }
      }
      userEvaluationAnswers {
        items {
          id
          questionSectionID
          questionID
          answerID
          answerValue
          createdAt
          modifiedAt
        }
      }
      userPaymentTransactions {
        items {
          id
          transactionID
          referenceNo
          status
          chequeNumber
          bankName
          eNetsTransactionID
          creditCardNumber
          paymentMethod
          modeOfDelivery
          language
          amount
          userID
          fullname
          identificationNumber
          identificationType
          createdAt
          modifiedAt
          remarks
        }
      }
    }
  }
`;
export const getUserPaymentTransactionRecord = /* GraphQL */ `
  query GetUserPaymentTransactionRecord($id: ID) {
    getUserPaymentTransactionRecord(id: $id) {
      id
      transactionID
      referenceNo
      status
      chequeNumber
      bankName
      eNetsTransactionID
      creditCardNumber
      paymentMethod
      modeOfDelivery
      language
      amount
      userID
      fullname
      identificationNumber
      identificationType
      createdAt
      modifiedAt
      remarks
    }
  }
`;
export const getCourseModule = /* GraphQL */ `
  query GetCourseModule($id: ID) {
    getCourseModule(id: $id) {
      id
      sequence
      title
      slug
      img
      createdAt
      modifiedAt
      course {
        items {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCourseModules = /* GraphQL */ `
  query ListCourseModules(
    $filter: ModelCourseModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID) {
    getCourse(id: $id) {
      id
      sequence
      title
      slug
      name
      type
      backgroundImg
      createdAt
      modifiedAt
      module {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        items {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseSection = /* GraphQL */ `
  query GetCourseSection($id: ID) {
    getCourseSection(id: $id) {
      id
      sequence
      title
      text
      type
      backgroundImg
      createdAt
      modifiedAt
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseContent {
        items {
          id
          sequence
          title
          text
          mediaURL
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quiz {
        items {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        nextToken
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCourseSections = /* GraphQL */ `
  query ListCourseSections(
    $filter: ModelCourseSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseSections(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseContent = /* GraphQL */ `
  query GetCourseContent($id: ID) {
    getCourseContent(id: $id) {
      id
      sequence
      title
      text
      mediaURL
      createdAt
      modifiedAt
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCourseContents = /* GraphQL */ `
  query ListCourseContents(
    $filter: ModelCourseContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        title
        text
        mediaURL
        createdAt
        modifiedAt
        courseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseResource = /* GraphQL */ `
  query GetCourseResource($id: ID) {
    getCourseResource(id: $id) {
      id
      language
      title
      text
      media
      explanation
      courseContent {
        id
        sequence
        title
        text
        mediaURL
        createdAt
        modifiedAt
        courseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      course {
        id
        sequence
        title
        slug
        name
        type
        backgroundImg
        createdAt
        modifiedAt
        module {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      courseModule {
        id
        sequence
        title
        slug
        img
        createdAt
        modifiedAt
        course {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizAnswerOption {
        id
        sequence
        text
        isCorrect
        explanation
        subQuestionIndex
        createdAt
        modifiedAt
        quizQuestion {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCourseResources = /* GraphQL */ `
  query ListCourseResources(
    $filter: ModelCourseResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        language
        title
        text
        media
        explanation
        courseContent {
          id
          sequence
          title
          text
          mediaURL
          createdAt
          modifiedAt
          updatedAt
        }
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseModule {
          id
          sequence
          title
          slug
          img
          createdAt
          modifiedAt
          updatedAt
        }
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        quizAnswerOption {
          id
          sequence
          text
          isCorrect
          explanation
          subQuestionIndex
          createdAt
          modifiedAt
          updatedAt
        }
        quizQuestion {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserProgress = /* GraphQL */ `
  query GetUserProgress($id: ID) {
    getUserProgress(id: $id) {
      id
      userID
      courseID
      moduleID
      progressPercentage
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const listUserProgresss = /* GraphQL */ `
  query ListUserProgresss(
    $id: ID
    $filter: ModelUserProgressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserProgresss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        courseID
        moduleID
        progressPercentage
        createdAt
        modifiedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID) {
    getQuiz(id: $id) {
      id
      type
      title
      description
      createdAt
      modifiedAt
      courseSectionID
      quizCourseSection {
        id
        sequence
        title
        text
        type
        backgroundImg
        createdAt
        modifiedAt
        course {
          id
          sequence
          title
          slug
          name
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        courseContent {
          nextToken
        }
        quiz {
          nextToken
        }
        courseResource {
          nextToken
        }
        updatedAt
      }
      question {
        items {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listQuizs = /* GraphQL */ `
  query ListQuizs(
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuizQuestion = /* GraphQL */ `
  query GetQuizQuestion($id: ID) {
    getQuizQuestion(id: $id) {
      id
      sequence
      title
      explanation
      createdAt
      modifiedAt
      quiz {
        id
        type
        title
        description
        createdAt
        modifiedAt
        courseSectionID
        quizCourseSection {
          id
          sequence
          title
          text
          type
          backgroundImg
          createdAt
          modifiedAt
          updatedAt
        }
        question {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      answerOptions {
        items {
          id
          sequence
          text
          isCorrect
          explanation
          subQuestionIndex
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listQuizQuestions = /* GraphQL */ `
  query ListQuizQuestions(
    $filter: ModelQuizQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuizAnswerOption = /* GraphQL */ `
  query GetQuizAnswerOption($id: ID) {
    getQuizAnswerOption(id: $id) {
      id
      sequence
      text
      isCorrect
      explanation
      subQuestionIndex
      createdAt
      modifiedAt
      quizQuestion {
        id
        sequence
        title
        explanation
        createdAt
        modifiedAt
        quiz {
          id
          type
          title
          description
          createdAt
          modifiedAt
          courseSectionID
          updatedAt
        }
        answerOptions {
          nextToken
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      quizResource {
        items {
          id
          language
          title
          text
          media
          explanation
          createdAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listQuizAnswerOptions = /* GraphQL */ `
  query ListQuizAnswerOptions(
    $filter: ModelQuizAnswerOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizAnswerOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sequence
        text
        isCorrect
        explanation
        subQuestionIndex
        createdAt
        modifiedAt
        quizQuestion {
          id
          sequence
          title
          explanation
          createdAt
          modifiedAt
          updatedAt
        }
        quizResource {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvaluationQuestionSection = /* GraphQL */ `
  query GetEvaluationQuestionSection($id: ID) {
    getEvaluationQuestionSection(id: $id) {
      id
      sequence
      title
      type
      createdAt
      modifiedAt
      question {
        items {
          id
          sequence
          title
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      ratingScale {
        items {
          id
          sequence
          text
          value
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const listEvaluationQuestionSections = /* GraphQL */ `
  query ListEvaluationQuestionSections(
    $filter: ModelEvaluationQuestionSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvaluationQuestionSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvaluationQuestion = /* GraphQL */ `
  query GetEvaluationQuestion($id: ID) {
    getEvaluationQuestion(id: $id) {
      id
      sequence
      title
      createdAt
      modifiedAt
      section {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listEvaluationQuestions = /* GraphQL */ `
  query ListEvaluationQuestions(
    $filter: ModelEvaluationQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvaluationQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sequence
        title
        createdAt
        modifiedAt
        section {
          id
          sequence
          title
          type
          createdAt
          modifiedAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourseContentExport = /* GraphQL */ `
  query GetCourseContentExport($id: ID) {
    getCourseContentExport(id: $id) {
      id
      fileName
      expiryDate
      email
      status
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const listCourseContentExports = /* GraphQL */ `
  query ListCourseContentExports(
    $filter: ModelCourseContentExportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseContentExports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fileName
        expiryDate
        email
        status
        createdAt
        modifiedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvaluationRatingScale = /* GraphQL */ `
  query GetEvaluationRatingScale($id: ID) {
    getEvaluationRatingScale(id: $id) {
      id
      sequence
      text
      value
      createdAt
      modifiedAt
      evaluationQuestionSection {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listEvaluationRatingScales = /* GraphQL */ `
  query ListEvaluationRatingScales(
    $filter: ModelEvaluationRatingScaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvaluationRatingScales(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sequence
        text
        value
        createdAt
        modifiedAt
        evaluationQuestionSection {
          id
          sequence
          title
          type
          createdAt
          modifiedAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchEvaluationQuestionSections = /* GraphQL */ `
  query SearchEvaluationQuestionSections(
    $filter: SearchableEvaluationQuestionSectionFilterInput
    $sort: SearchableEvaluationQuestionSectionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEvaluationQuestionSections(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        sequence
        title
        type
        createdAt
        modifiedAt
        question {
          nextToken
        }
        ratingScale {
          nextToken
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchEvaluationQuestions = /* GraphQL */ `
  query SearchEvaluationQuestions(
    $filter: SearchableEvaluationQuestionFilterInput
    $sort: SearchableEvaluationQuestionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEvaluationQuestions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        sequence
        title
        createdAt
        modifiedAt
        section {
          id
          sequence
          title
          type
          createdAt
          modifiedAt
          updatedAt
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchCourseContentExports = /* GraphQL */ `
  query SearchCourseContentExports(
    $filter: SearchableCourseContentExportFilterInput
    $sort: SearchableCourseContentExportSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCourseContentExports(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        fileName
        expiryDate
        email
        status
        createdAt
        modifiedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchEvaluationRatingScales = /* GraphQL */ `
  query SearchEvaluationRatingScales(
    $filter: SearchableEvaluationRatingScaleFilterInput
    $sort: SearchableEvaluationRatingScaleSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchEvaluationRatingScales(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        sequence
        text
        value
        createdAt
        modifiedAt
        evaluationQuestionSection {
          id
          sequence
          title
          type
          createdAt
          modifiedAt
          updatedAt
        }
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getUserQuizAnswer = /* GraphQL */ `
  query GetUserQuizAnswer($id: ID) {
    getUserQuizAnswer(id: $id) {
      id
      questionID
      subQuestionIndex
      answerID
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const listUserQuizAnswers = /* GraphQL */ `
  query ListUserQuizAnswers(
    $filter: ModelUserQuizAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserQuizAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        questionID
        subQuestionIndex
        answerID
        createdAt
        modifiedAt
        updatedAt
        user {
          id
          identificationNumber
          identificationType
          passportNumber
          fullname
          nationality
          mobilePhoneNumber
          email
          dob
          dateOfAttendance
          language
          mode
          skipSingPass
          firstTimer
          paymentStatus
          disallowUserSkippingCourse
          validityDate
          emailMarketingOption
          smsMarketingOption
          hasCompletedAllCourse
          hasRefunded
          previousEmploymentAgency
          createdAt
          modifiedAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID) {
    getUser(id: $id) {
      id
      identificationNumber
      identificationType
      passportNumber
      fullname
      nationality
      mobilePhoneNumber
      email
      dob
      dateOfAttendance
      language
      mode
      skipSingPass
      firstTimer
      paymentStatus
      disallowUserSkippingCourse
      validityDate
      emailMarketingOption
      smsMarketingOption
      hasCompletedAllCourse
      hasRefunded
      previousEmploymentAgency
      createdAt
      modifiedAt
      updatedAt
      userQuizAnswers {
        items {
          id
          questionID
          subQuestionIndex
          answerID
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userEvaluationAnswers {
        items {
          id
          questionSectionID
          questionID
          answerID
          answerValue
          createdAt
          modifiedAt
          updatedAt
        }
        nextToken
      }
      userPaymentTransactions {
        items {
          id
          transactionID
          referenceNo
          status
          chequeNumber
          bankName
          eNetsTransactionID
          creditCardNumber
          paymentMethod
          modeOfDelivery
          language
          amount
          userID
          fullname
          identificationNumber
          identificationType
          createdAt
          modifiedAt
          remarks
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getPaymentLog = /* GraphQL */ `
  query GetPaymentLog($id: ID) {
    getPaymentLog(id: $id) {
      id
      tranID
      txnStatus
      txnSignature
      txnSignature2
      tranDate
      merchantTranID
      responseCode
      responseDesc
      customerID
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const listPaymentLogs = /* GraphQL */ `
  query ListPaymentLogs(
    $filter: ModelPaymentLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tranID
        txnStatus
        txnSignature
        txnSignature2
        tranDate
        merchantTranID
        responseCode
        responseDesc
        customerID
        createdAt
        modifiedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserEvaluationAnswer = /* GraphQL */ `
  query GetUserEvaluationAnswer($id: ID) {
    getUserEvaluationAnswer(id: $id) {
      id
      questionSectionID
      questionID
      answerID
      answerValue
      createdAt
      modifiedAt
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const listUserEvaluationAnswers = /* GraphQL */ `
  query ListUserEvaluationAnswers(
    $filter: ModelUserEvaluationAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserEvaluationAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionSectionID
        questionID
        answerID
        answerValue
        createdAt
        modifiedAt
        updatedAt
        user {
          id
          identificationNumber
          identificationType
          passportNumber
          fullname
          nationality
          mobilePhoneNumber
          email
          dob
          dateOfAttendance
          language
          mode
          skipSingPass
          firstTimer
          paymentStatus
          disallowUserSkippingCourse
          validityDate
          emailMarketingOption
          smsMarketingOption
          hasCompletedAllCourse
          hasRefunded
          previousEmploymentAgency
          createdAt
          modifiedAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchUserEvaluationAnswers = /* GraphQL */ `
  query SearchUserEvaluationAnswers(
    $filter: SearchableUserEvaluationAnswerFilterInput
    $sort: SearchableUserEvaluationAnswerSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserEvaluationAnswers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        questionSectionID
        questionID
        answerID
        answerValue
        createdAt
        modifiedAt
        updatedAt
        user {
          id
          identificationNumber
          identificationType
          passportNumber
          fullname
          nationality
          mobilePhoneNumber
          email
          dob
          dateOfAttendance
          language
          mode
          skipSingPass
          firstTimer
          paymentStatus
          disallowUserSkippingCourse
          validityDate
          emailMarketingOption
          smsMarketingOption
          hasCompletedAllCourse
          hasRefunded
          previousEmploymentAgency
          createdAt
          modifiedAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getAnnouncement = /* GraphQL */ `
  query GetAnnouncement($id: ID) {
    getAnnouncement(id: $id) {
      id
      title
      text
      startDate
      endDate
      createdAt
      modifiedAt
      updatedAt
    }
  }
`;
export const listAnnouncements = /* GraphQL */ `
  query ListAnnouncements(
    $filter: ModelAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnnouncements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        text
        startDate
        endDate
        createdAt
        modifiedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchAnnouncements = /* GraphQL */ `
  query SearchAnnouncements(
    $filter: SearchableAnnouncementFilterInput
    $sort: SearchableAnnouncementSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAnnouncements(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        title
        text
        startDate
        endDate
        createdAt
        modifiedAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getPaymentTransaction = /* GraphQL */ `
  query GetPaymentTransaction($id: ID) {
    getPaymentTransaction(id: $id) {
      id
      transactionID
      referenceNo
      status
      chequeNumber
      bankName
      eNetsTransactionID
      creditCardNumber
      paymentMethod
      modeOfDelivery
      language
      amount
      userID
      fullname
      identificationNumber
      identificationType
      createdAt
      modifiedAt
      remarks
      updatedAt
      user {
        id
        identificationNumber
        identificationType
        passportNumber
        fullname
        nationality
        mobilePhoneNumber
        email
        dob
        dateOfAttendance
        language
        mode
        skipSingPass
        firstTimer
        paymentStatus
        disallowUserSkippingCourse
        validityDate
        emailMarketingOption
        smsMarketingOption
        hasCompletedAllCourse
        hasRefunded
        previousEmploymentAgency
        createdAt
        modifiedAt
        updatedAt
        userQuizAnswers {
          nextToken
        }
        userEvaluationAnswers {
          nextToken
        }
        userPaymentTransactions {
          nextToken
        }
      }
    }
  }
`;
export const listPaymentTransactions = /* GraphQL */ `
  query ListPaymentTransactions(
    $filter: ModelPaymentTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        transactionID
        referenceNo
        status
        chequeNumber
        bankName
        eNetsTransactionID
        creditCardNumber
        paymentMethod
        modeOfDelivery
        language
        amount
        userID
        fullname
        identificationNumber
        identificationType
        createdAt
        modifiedAt
        remarks
        updatedAt
        user {
          id
          identificationNumber
          identificationType
          passportNumber
          fullname
          nationality
          mobilePhoneNumber
          email
          dob
          dateOfAttendance
          language
          mode
          skipSingPass
          firstTimer
          paymentStatus
          disallowUserSkippingCourse
          validityDate
          emailMarketingOption
          smsMarketingOption
          hasCompletedAllCourse
          hasRefunded
          previousEmploymentAgency
          createdAt
          modifiedAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchPaymentTransactions = /* GraphQL */ `
  query SearchPaymentTransactions(
    $filter: SearchablePaymentTransactionFilterInput
    $sort: SearchablePaymentTransactionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPaymentTransactions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        transactionID
        referenceNo
        status
        chequeNumber
        bankName
        eNetsTransactionID
        creditCardNumber
        paymentMethod
        modeOfDelivery
        language
        amount
        userID
        fullname
        identificationNumber
        identificationType
        createdAt
        modifiedAt
        remarks
        updatedAt
        user {
          id
          identificationNumber
          identificationType
          passportNumber
          fullname
          nationality
          mobilePhoneNumber
          email
          dob
          dateOfAttendance
          language
          mode
          skipSingPass
          firstTimer
          paymentStatus
          disallowUserSkippingCourse
          validityDate
          emailMarketingOption
          smsMarketingOption
          hasCompletedAllCourse
          hasRefunded
          previousEmploymentAgency
          createdAt
          modifiedAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
